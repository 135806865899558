import React from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Divider,
  ListItemButton,
  ListItemText,
  List,
  ListItemIcon,
} from "@mui/material";
import { HeadingText } from "../CustomComps/Text";
import { ChevronRight } from "@mui/icons-material";
import { motion } from "framer-motion";

export function DescriptionItemView(props) {
  const { children } = props;
  return (
    <ListItemButton disableGutters>
      <ListItemIcon>
        <ChevronRight />
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  );
}

/*
  String: title, employeer, timeFrame
  [String]: descriptionList
*/
function WorkItemView({ work_exp }) {
  const { title, employer, timeFrame, descriptionList } = work_exp;
  return (
    <Grid item xs={12}>
      <Grid
        item
        xs={12}
        sm={12}
        padding={"1em 0"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {/* Job title */}
        <Grid item xs={12} sm={6} textAlign={"left"}>
          <Typography variant="p" component={"h3"}>
            {title}
          </Typography>
        </Grid>

        {/* Employeer and time period */}
        <Grid
          item
          xs={12}
          sm={6}
          textAlign={{
            xs: "left",
            sm: "right",
          }}
        >
          <Typography color={"text.disabled"}>{employer}</Typography>
          <Typography color={"text.disabled"}>{timeFrame}</Typography>
        </Grid>
      </Grid>

      {/* Description */}
      <Grid item padding={"0em"}>
        <Typography variant="p" component={"h3"} color={"text.secondary"}>
          Description
        </Typography>
        <List>
          {descriptionList.map((e, index) => (
            <DescriptionItemView key={index}>{e}</DescriptionItemView>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default function ExperienceView() {
  let workExperiences = [
    {
      title: "J.P. Morgan Software Engineering Virtual Experience on Forage",
      employer: "J.P. Morgan",
      timeFrame: "June 2024",
      descriptionList: [
        "Set up a local dev environment by downloading the necessary files, tools and dependencies.",
        "Fixed broken files in the repository to make web application output correctly.",
        "Used JPMorgan Chase’s open-source library called Perspective to generate a live graph that displays a data feed in a clear and visually appealing way for traders to monitor.",
      ],
    },
    {
      title: "Computer Science Tutor",
      employer: "Fresno City College",
      timeFrame: "08/2020 - 09/2022",
      descriptionList: [
        "Conducted educational sessions for learners on diverse subjects including programming, data structures, algorithms, and databases.",
        "Simplified intricate ideas and methodologies using easy-to-understand language, practical examples, illustrative diagrams, and interactive exercises.",
        "Offered technical aid to learners through various channels such as phone, email, and chat, addressing a range of issues encompassing hardware, software, networking, and security.",
        "Supported learners with their homework tasks, projects, and exam preparation, making certain they grasped the stipulations and anticipations.",
      ],
    },
    {
      title: "Web Development",
      employer: "University of Santa Barbara",
      timeFrame: "08/2021 - 09/2022",
      descriptionList: [
        "Worked with professors to build their personal website.",
        "Collaborated with faculty members to implement modifications, and executing as well as issuing instructions.",
        "Contributed to the college catalog by refreshing web pages.",
        "Mastered diverse strategies to enhance website usability, including logo design, animation creation, and data processing for efficient event renderings.",
      ],
    },
    {
      title: "System Administrator",
      employer: "Lahoria Transport Inc.",
      timeFrame: "01/2020 - Present",
      descriptionList: [
        "Managing their employees, and creating, updating their company policies, and agreements.",
        "Configuration of domains, managing multiple host accounts.",
        "Setting up environment for user, and installation of software.",
        "Troubleshooting in terms of any software related or hardware.",
      ],
    },
  ];

  window.scrollTo(0, 0);
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Container maxWidth="lg" id="main_container">
        <Box
          margin={{
            xs: "0.5em",
            sm: "2em",
          }}
          textAlign={"left"}
        >
          <HeadingText text={"Work Experience"} />
          <Divider />

          {/* Entry # 1 */}
          {workExperiences.map((work, key) => {
            return (
              <>
                <WorkItemView key={key} work_exp={work} />
                <Divider />
              </>
            );
          })}

          <Box marginTop={"2em"} />

          {/* <HeadingText text={"Other Experience"} /> */}

          <Typography
            variant="body2"
            color={"text.secondary"}
            sx={{
              fontSize: { xs: 16, sm: 22 },
              padding: "1em 0",
            }}
          >
            I possess six years of hands-on experience in the field of computer
            science, which includes a variety of personal projects and
            university projects. My journey has been marked by a deep dive into
            the theoretical aspects of computing, supplemented by university,
            self-learning from various books, and online-courses.
            <br />
            <br />
            I’ve independently implemented my knowledge across a range of
            applications, from web and desktop applications to native and
            framework-based mobile apps. This comprehensive experience has
            equipped me with the ability to swiftly adapt and execute similar
            tasks in the future.
          </Typography>
        </Box>
      </Container>
    </motion.main>
  );
}
