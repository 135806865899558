import { Box, Typography } from "@mui/material";
import PAGE_NOT_FOUND from "../Images/svgs/page_not_found.svg";
export default function PageNotFound() {
  window.scrollTo(0, 0);

  return (
    <Box
      height={"100vh"}
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      sx={{
        background: `url(${PAGE_NOT_FOUND}) center no-repeat`,
      }}
    >
      <Typography variant="h2" component={"h1"} color={"text.secondary"}>
        404 Page Not Found
      </Typography>
    </Box>
  );
}
