import React from "react";
import { motion } from "framer-motion";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  Grid,
  CardActionArea,
  LinearProgress,
} from "@mui/material";
import { HeadingText } from "../CustomComps/Text";
import "../Styles/Education.css";
// images
import CSFU_IMG from "../Images/edu_csfu.jpg";
import AI_ML_IMG from "../Images/edu_ml_ai.png";
import CLOVIS_COLLEGE_IMG from "../Images/clovis_college.jpg";
import FRESNO_COLLEGE_IMG from "../Images/fresno_college.jpg";
import WEB_DEV_IMG from "../Images/web_dev.jpg";

import java_SOLO_CERT from "../certs/java.png";
import js_SOLO_CERT from "../certs/js.png";
import cpp_SOLO_CERT from "../certs/cpp.png";
import py_SOLO_CERT from "../certs/python.png";
import JP_CERT from "../certs/jp.jpg";

// svg certs
import TECHNICAL_CERT from "../certs/technical support.svg";
import BITS_BYTES_CERT from "../certs/bits and bytes.svg";
import IT_SECURITY_CERT from "../certs/it security.svg";
import OS_CERT from "../certs/operating systems.svg";
import SYS_ADMIN_CERT from "../certs/sys admin.svg";
import JAVA_CERT from "../certs/java_intermediate certificate.svg";
import { Link } from "react-router-dom";

function Education_Entry({
  title,
  description,
  image,
  progress,
  height,
  cardHeight,
  link,
}) {
  const card = (
    <div aria-label={title}>
      {" "}
      <CardActionArea>
        <Card
          sx={{
            height: cardHeight !== "" ? cardHeight : "auto",
          }}
        >
          {image && (
            <Box
              sx={{
                background: `url(${image}) no-repeat center`,
                backgroundSize: "cover",
                height: { xs: "260px", sm: height !== "" ? height : "360px" },
              }}
            />
          )}
          <LinearProgress
            value={progress}
            variant="determinate"
            sx={{
              height: "5px",
            }}
          />
          <CardContent>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sm={12}>
                <Typography gutterBottom variant="h5" component="div">
                  {title}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography gutterBottom variant="p" component="div">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardActionArea>
    </div>
  );
  return link ? (
    <Link style={{ textDecoration: "none" }} to={link} target="_blank">
      {card}
    </Link>
  ) : (
    card
  );
}

function Cert_View({ title, image, link }) {
  return (
    <Grid item md={4} sm={6} xs={12}>
      <div aria-label={title}>
        <Card>
          <CardActionArea target={"_blank"} href={link}>
            <CardContent>
              <img
                src={image}
                style={{
                  width: "100%",
                }}
              />
              <Typography
                variant="small"
                component={"small"}
                color={"text.secondary"}
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  );
}

export default function EducationView() {
  let certs = [
    {
      title: "J.P. Morgan Software Engineering Job Simulation",
      image: JP_CERT,
      link: "https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_4C6F9r5REPTffRLgy_1717896477651_completion_certificate.pdf",
    },
    {
      title: "Technical Support",
      image: TECHNICAL_CERT,
      link: "https://www.coursera.org/account/accomplishments/certificate/BNWQUZWBL29C",
    },
    {
      title: "IT Security",
      image: IT_SECURITY_CERT,
      link: "https://www.coursera.org/account/accomplishments/certificate/JY6BPLGVG2FL",
    },
    {
      title: "Operating Systems",
      image: OS_CERT,
      link: "https://www.coursera.org/account/accomplishments/certificate/D3PTVWCRHY23",
    },
    {
      title: "Computer Networking",
      image: BITS_BYTES_CERT,
      link: "https://www.coursera.org/account/accomplishments/certificate/EACZN9MQHUB3",
    },
    {
      title: "System Administration",
      image: SYS_ADMIN_CERT,
      link: "https://www.coursera.org/account/accomplishments/certificate/EQFES5CSBGAY",
    },
    {
      title: "Java Intermediate",
      image: JAVA_CERT,
      link: "https://www.hackerrank.com/certificates/e64008b133f1",
    },
    {
      title: "C++ Theoretical",
      image: cpp_SOLO_CERT,
      link: "https://www.sololearn.com/certificates/CT-JITIDW3J",
    },
    {
      title: "Python Core",
      image: py_SOLO_CERT,
      link: "https://www.sololearn.com/certificates/CT-S52QLN8P",
    },
    {
      title: "Javascript Theoretical",
      image: js_SOLO_CERT,
      link: "https://www.sololearn.com/certificates/CT-QIGBGG2V",
    },
    {
      title: "Java Core",
      image: java_SOLO_CERT,
      link: "https://www.sololearn.com/certificates/CC-B2751YA5",
    },
  ];

  const courses = [
    {
      name: "CSCI 40",
      description: "Programming Concepts with C++",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34586",
    },
    {
      name: "CSCI 41",
      description: "Data Structures and Algorithms I",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34585",
    },
    {
      name: "CIT 63",
      description: "Java Programming",
      link: "",
    },
    {
      name: "CSCI 26/60",
      description:
        "Math for Computer Science.\nLinear Algebra, Calculus I, II, III",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=2&coid=9928",
    },
    {
      name: "CSCI 45/80/112",
      description: "Computer Architecture/Organization and Assembly Language I",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34579",
    },

    {
      name: "CSCI 113",
      description: "Computer Architecture/Organization Assembly Language II",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34578",
    },
    {
      name: "CIT 82",
      description: "Web Development",
      link: "",
    },
    {
      name: "CSCI 114",
      description: "Operating Systems",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34577",
    },
    {
      name: "CSCI 115",
      description: "Data Structures and Algorithms II",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34576",
    },
    {
      name: "CSCI 117",
      description: "Structures of Programming Languages",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34575",
    },
    {
      name: "CSCI 119",
      description: "Formal Languages and Automata",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34574",
    },
    {
      name: "CSCI 178",
      description: "Game Development with C++",
      link: "https://catalog.fresnostate.edu/preview_course_nopop.php?catoid=5&coid=34558",
    },
  ];

  window.scrollTo(0, 0);

  const Tile = ({ name, description, link }) => (
    <Grid md={4} sm={6} xs={12} className="courseTile" key={name}>
      <Link
        to={link}
        target="_blank"
        style={{ textDecoration: "none", color: "black", gap: 10 }}
      >
        <h2 style={{ fontWeight: "lighter" }}>{name}</h2>
        <p style={{ padding: "10px" }}>{description}</p>
      </Link>
    </Grid>
  );

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Container maxWidth="lg" id="main_container">
        <Box
          margin={{
            xs: "0.5em",
            sm: "2em",
          }}
          textAlign={"left"}
        >
          <HeadingText text="In Progress" />
          <Divider />
          <br />

          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <Education_Entry
                title="Computer Science - BA/MS"
                description="Bachelor's Expected May 2025"
                progress={80}
                cardHeight={"380px"}
                image={CSFU_IMG}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <Education_Entry
                title="Machine Learning / AI"
                description="Coursework - IBM career's"
                progress={40}
                cardHeight={"380px"}
                image={AI_ML_IMG}
              />
            </Grid>
            <br />

            {/* <Grid item md={12}>
              <br />
              <BodyDescription
                text={`I’m also dedicating time to Computer Engineering because I have
                a keen interest in it. I enjoy working with hardware components,
                especially those related to embedded programming. Additionally,
                I’m open to learning new technologies as long as they are
                practical, beneficial, and serve a purpose`}
              />
            </Grid> */}
          </Grid>
          <br />
          <br />

          <HeadingText text={"Completed"} />
          <Divider />

          <h2>Coursework</h2>

          <Grid container spacing={2}>
            {courses.map((course, key) => (
              <Grid item md={4} sm={6} xs={12}>
                <Education_Entry
                  title={course["name"]}
                  description={course["description"]}
                  progress={0}
                  height="auto"
                  link={course["link"]}
                />
              </Grid>
            ))}
          </Grid>

          <br />
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <Education_Entry
                title="Computer Science - AS"
                description="Clovis Community College - 2022"
                progress={100}
                height="240px"
                cardHeight="390px"
                image={CLOVIS_COLLEGE_IMG}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Education_Entry
                title="Mathematics - AS"
                description="Fresno City College - 2022"
                progress={100}
                height="240px"
                cardHeight="390px"
                image={FRESNO_COLLEGE_IMG}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Education_Entry
                title="Web development"
                description="Front-end, Back-end, Sessions, API's, Databases, ReactJs."
                progress={100}
                height="240px"
                cardHeight="390px"
                image={WEB_DEV_IMG}
              />
            </Grid>

            {certs.map((item, index) => (
              <Cert_View
                key={index}
                title={item["title"]}
                image={item["image"]}
                link={item["link"]}
              />
            ))}
          </Grid>
        </Box>
      </Container>
    </motion.main>
  );
}
