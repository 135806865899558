import React from "react";
import "../Styles/Home.css";
import "../Styles/Skills.css";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { HeadingText } from "./Text";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import svg_algorithm_analysis from "../Images/svgs/technical.svg";
import svg_focus from "../Images/svgs/focus.svg";
import svg_logic_building from "../Images/svgs/logic.svg";
import svg_data_structure from "../Images/svgs/data_st.svg";
import svg_sys_admin from "../Images/svgs/sys_admin.svg";
import svg_database from "../Images/svgs/data_base.svg";
import svg_ml from "../Images/svgs/machine_learning.svg";

function SkillsItemView({ skill_description }) {
  const { image_name, title, description } = skill_description;

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Card
        className="skills_card"
        sx={{
          height: { xs: "fit-content", sm: "600px", md: "500px" },
        }}
      >
        <CardMedia
          sx={{
            // border: "1px solid red",
            width: {
              xs: "75% !important",
              sm: "100% !important",
              md: "auto !important",
            },
          }}
          className="skills_img"
          component={"img"}
          alt="algorithms"
          image={image_name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color={"text.secondary"}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function Content_TopSkills() {
  const skill_algorithms = {
    image_name: svg_algorithm_analysis,
    title: "Algorithm Analysis",
    description: `Ensuring that an algorithm is efficient, accurate, and reliable
    while minimizing resource usage is paramount. Striving for
    minimal CPU usage, memory consumption, and optimal utilization
    of other resources is the key to efficient algorithm design.
    Minimizing errors and maximizing throughput are essential for
    creating robust and high-performing algorithms.`,
  };

  const skill_data_strs = {
    image_name: svg_data_structure,
    title: "Data Structures",
    description: `Mastering data structures is just the beginning. The real art lies in knowing where, when, and how to deploy them effectively. Efficiency and time-effectiveness are the cornerstones of choosing the right structure. Simplicity and fluidity are the essence of great design. My toolkit includes both linear and non-linear structures, from Linked Lists to Binary Trees and Graphs.`,
  };

  const skill_circuits = {
    image_name: svg_logic_building,
    title: "Circuits and ICS",
    description: `I am passionate about working with circuits, implementing sensors, and programming them to meet specific application requirements. My experience includes working with various chips such as ESP32, Arduino, and Raspberry Pi, as well as a variety of sensors and ICs. Through personal projects, I have developed understanding of how to efficiently implement and utilize libraries, ensuring that circuits consume minimal power while maintaining effectiveness.`,
  };

  const skill_dbs = {
    image_name: svg_database,
    title: "Databases / Authentication",
    description: `In today’s rapidly evolving technological landscape, we’re generating and processing data at an unprecedented rate. This surge in data creation necessitates efficient, reliable, and authentic data management. As a tech enthusiast, I’ve gained valuable experience in managing both SQL and NoSQL databases. I’m constantly learning and adapting, always on the lookout for opportunities to leverage the latest database technologies.`,
  };

  const skill_sys_admin = {
    image_name: svg_sys_admin,
    title: "System Administration",
    description: `As a System Administrator, managing multiple users and groups with appropriate permissions and access control is crucial to ensure safety and authenticity. It’s my responsibility to provide a ready-to-use and properly functioning environment for all users. I’m continually enhancing my skills in both Linux and Windows operating systems, striving for excellence in every aspect of system administration.`,
  };

  const skill_ml = {
    image_name: svg_ml,
    title: "Machine Learning / AI",
    description: `With AI evolving at an exponential rate and becoming integral to many aspects of life, I’ve taken the initiative to dive into this field. I’ve taken courses on Coursera and reading a book that delve into Machine Learning algorithms. I’m investing my time and energy into mastering AI and Machine Learning, committed to staying at the forefront of this rapidly advancing field.`,
  };

  return (
    <Box
      margin={{
        xs: "0.5em",
        sm: "2em",
      }}
    >
      <div className="section_title_and_image">
        <img
          alt="Technical analysis"
          src={svg_focus}
          style={{
            boxSizing: "border-box",
            width: "15%",
          }}
        />
        <HeadingText text="Technicals" />
      </div>
      <Divider />

      <Grid className="sub_container" spacing={4} container>
        <SkillsItemView skill_description={skill_algorithms} />
        <SkillsItemView skill_description={skill_data_strs} />
        <SkillsItemView skill_description={skill_circuits} />
        <SkillsItemView skill_description={skill_dbs} />
        <SkillsItemView skill_description={skill_sys_admin} />
        <SkillsItemView skill_description={skill_ml} />
      </Grid>
    </Box>
  );
}
