import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { HeadingText } from "../CustomComps/Text";
import {
  Container,
  Box,
  Divider,
  Grid,
  TextField,
  Button,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { GitHub, LinkedIn, Send } from "@mui/icons-material";

import emailjs from "@emailjs/browser";

export default function SocialLinks() {
  const [form_name, setFormName] = useState(null);
  const [form_email, setFormEmail] = useState(null);
  const [form_msg, setFormMsg] = useState(null);
  const [user_name, setUserName] = useState(null);

  const [showAlert, setShowAlert] = useState(false);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const msgRef = useRef(null);

  window.scrollTo(0, 0);
  emailjs.init({
    publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
  });

  const handleEmail = (email, username, msg) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        {
          from_email: email, //"mannys6582@gmail.com",
          from_name: username, // "Manmeet Singh",
          message: msg,
        }
      )
      .then(
        (response) => {
          // display alert box
          setUserName(form_name);
          setShowAlert(true);
          setTimeout(() => {
            if (setShowAlert) setShowAlert(false);
          }, 10000);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  const handleNameValue = () => {
    // name pattern - which should always be in range of a to z
    // /g for search gloablly in string
    // including spaces and A to Z and a to z characters
    // \s for space characters
    // get rid of spaces

    let name_val = nameRef.current.value.trim();

    const name_re = /[A-Z|a-z|\s]/g;
    let output = name_val.match(name_re);
    if (output && name_val !== "") {
      output = output.join("");
      setFormName(output);
    } else {
      setFormName(null);
    }
  };

  const handleEmailRef = () => {
    // get rid of empty spaces
    let email_val = emailRef.current.value.trim();

    // HTML 5 email validation pattern
    const email_re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
    let output = email_val.match(email_re);
    if (output && email_val !== "") {
      output = output.join("");
      setFormEmail(output);
    } else {
      setFormEmail(null);
    }
  };

  const handleMessageRef = () => {
    setFormMsg(msgRef.current.value ?? "");
    // get rid of empty spaces
    let msg_val = msgRef.current.value.trim();

    // HTML 5 email validation pattern
    const msg_re = /[a-zA-Z0-9\s]/g;
    let output = msg_val.match(msg_re);
    if (output && msg_val !== "") {
      output = output.join("");
      setFormMsg(output);
    } else {
      setFormMsg(null);
    }
  };

  const clearForm = () => {
    document.getElementById("myForm").reset();
    setFormName(null);
    setFormEmail(null);
    setFormMsg(null);

    nameRef.current.value = null;
    emailRef.current.value = null;
    msgRef.current.value = null;
  };

  const AlertBox = (
    <Alert variant="filled" severity="success" sx={{ margin: "1em 0" }}>
      <AlertTitle>Email Sent!</AlertTitle>
      <Grid item xs={12}>
        <Typography variant="p">
          Hello {user_name}, thank you for reaching out! I’ve received your
          message and will get back to you as soon as possible. Your interest is
          greatly appreciated! 📬
        </Typography>
      </Grid>
    </Alert>
  );
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Container maxWidth="lg" id="main_container">
        <Box
          margin={{
            xs: "0.5em",
            sm: "2em",
          }}
          textAlign={"left"}
        >
          <HeadingText text="Connect with Me!" />
          <Divider />
          {showAlert ? AlertBox : <br />}
          <form
            id="myForm"
            onSubmit={(e) => {
              e.preventDefault();
              handleEmail(form_email, form_name, form_msg);
              clearForm();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  aria-label="username"
                  required={true}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  onChange={handleNameValue}
                  onBlur={() => {
                    nameRef.current.value = form_name;
                  }}
                  inputRef={nameRef}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  required={true}
                  type="email"
                  aria-label="email address"
                  fullWidth
                  onChange={handleEmailRef}
                  onBlur={() => {
                    emailRef.current.value = form_email;
                  }}
                  inputRef={emailRef}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  label="Message"
                  placeholder="Write your message here..."
                  variant="outlined"
                  required={true}
                  type="textarea"
                  aria-label="message"
                  fullWidth
                  multiline
                  rows={5}
                  onChange={handleMessageRef}
                  onBlur={() => {
                    msgRef.current.value = form_msg;
                  }}
                  inputRef={msgRef}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component="small"
                  color="text.secondary"
                >
                  Your email address is not being used to send this message;
                  it’s simply a way for me to get back to you. 📬✨
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  disabled={
                    form_name == null || form_email == null || form_msg == null
                  }
                  variant="contained"
                  type="submit"
                  startIcon={<Send />}
                >
                  Send Message
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  sx={{ margin: "0em 1em 1em 0em" }}
                  href="https://www.linkedin.com/in/manmeet-singh-692230167/"
                  target="_blank"
                  variant="contained"
                  startIcon={<LinkedIn />}
                >
                  Connect on Linked-In
                </Button>

                <Button
                  sx={{ margin: "0em 1em 1em 0em" }}
                  href="https://github.com/manmeetsingh7781"
                  target="_blank"
                  variant="contained"
                  color="secondary"
                  startIcon={<GitHub />}
                >
                  GitHub Account
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </motion.main>
  );
}
