import React from "react";
import { motion } from "framer-motion";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { BodyText, HeadingText } from "../CustomComps/Text";
import { ChevronRight } from "@mui/icons-material";

// videos
import PROJECT_GRAPH from "../projects/Graphs.mp4";
import PROJECT_DSA from "../projects/dsa.mp4";
import PROJECT_IDE from "../projects/C++ IDE.mp4";
import PROJECT_POS from "../projects/POS Systems.mp4";
import PROJECT_EARTHQUAKE from "../projects/Earthquake.mp4";
import PROJECT_PLANNER from "../projects/Planner.mp4";

// thumbnails
import PROJECT_GRAPH_THUMBNAIL from "../projects/thumbnails/graphing_tool.png";
import PROJECT_DSA_THUMBNAIL from "../projects/thumbnails/dsa.png";
import PROJECT_IDE_THUMBNAIL from "../projects/thumbnails/cpp_ide.png";
import PROJECT_POS_THUMBNAIL from "../projects/thumbnails/pos.png";
import PROJECT_EARTHQUAKE_THUMBNAIL from "../projects/thumbnails/earthquake.png";
import PROJECT_PLANNER_THUMBNAIL from "../projects/thumbnails/planner.png";

function ProjectItem({ props }) {
  let { title, video_src, thumbnail, descriptionList, tags, link } = props;
  return (
    <Grid
      item
      xs={12}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <br />
      <Typography variant="h5">
        <Link target="_blank" href={link} underline="none">
          🔗
          {title}
        </Link>
      </Typography>
      {/* <BodyText text={title} /> */}
      <br />
      <Grid item xs={12} display="flex" flexDirection={"row"} flexWrap={"wrap"}>
        <Grid item xs={12} md={6}>
          <video
            src={video_src}
            autoPlay={false}
            controls
            poster={thumbnail}
            title={title}
            style={{
              width: "100%",
              height: "100%",
              border: "0.2em solid rgba(0,0,0,0.3)",
              borderRadius: "1em",
              boxSizing: "border-box",
              boxShadow: `10px 10px 100px rgba(0, 0, 0, 0.2)`,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingLeft: { sm: "2em" } }}
          flexDirection={"column"}
        >
          <Typography variant="h5" padding={{ xs: "2em 0 0 0" }}>
            Description
          </Typography>
          <List>
            {descriptionList.map((item, index) => (
              <ListItemButton key={index}>
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
                <ListItemText>{item}</ListItemText>
              </ListItemButton>
            ))}
            {/* Tags */}
            <ListItemButton>
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
              <ListItemText>
                <span style={{ color: "red" }}>Tags</span>: {tags}
              </ListItemText>
            </ListItemButton>

            {/* Link */}
            {/* <ListItemButton href={link} target="_blank">
              <ListItemIcon>
                <LinkRounded />
              </ListItemIcon>
              <ListItemText>
                <span
                  style={{
                    color: "#1467f7",
                    borderRadius: "2px",
                  }}
                >
                  {link_description}
                </span>
              </ListItemText>
            </ListItemButton> */}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default function ProjectsView() {
  window.scrollTo(0, 0);

  const projects = [
    {
      title: "Algorithmic Insight Powered by Gemini AI",
      video_src: PROJECT_DSA,
      thumbnail: PROJECT_DSA_THUMBNAIL,
      descriptionList: [
        `Interactive Computer Science Learning: Engage with dynamic lessons on foundational topics like Data Structures and Algorithms.`,
        `Intelligent Assistance: Leverage Google’s Gemini AI for personalized lesson guidance, comprehension checks, and progress tracking.`,
        `Secure Progress Management: Utilize Google Authentication to safeguard user progress and personal data.`,
        `I utilized React Native for the application structure and Firebase for managing backend services, including storing and retrieving information, user authentication, and storing user media files`,
        `Integrated several other APIs such as React Native Charts for visualization, Markdown API for rendering AI-generated responses into Markdown, and Local Storage and File System API for collecting and caching user media files.`,
        `To enhance the app's UI/UX, I used the react-native-paper API for styling native components.`,
        `Last but not least I used syntax-highlighter API which helps to highlight keywords and color the code-block.`,
      ],
      tags: `Lessons, React, React Native, Database, Storage, Artifical Intelligence, Mobile App, Data Structures and Algorithms`,
      link: "https://github.com/manmeetsingh7781/Algorithmic-Insight",
      link_description: "Check out Github Repository",
    },
    {
      title: "Graphing tool with Machine Learning 🤖",
      video_src: PROJECT_GRAPH,
      thumbnail: PROJECT_GRAPH_THUMBNAIL,
      descriptionList: [
        `Multi-threaded application, given an equation it will plot a
        graph on the x and y axis.`,
        `I wrote ML algorithm and Matrix operations from scratch will
        deep understating of Multivariable Calculus and Linear Algebra.`,
        ` Users plot points on the axis, the Machine Learning algorithm
        determines the equation for those points.`,
      ],
      tags: `Java, JavaFX, API, ScriptEngine, String Parsing, JSON,
      Multi-threading, Parallel Processing.`,
      link: "https://github.com/manmeetsingh7781/Projects/tree/master/Graphs",
      link_description: "Check out Github Repository",
    },
    {
      title: "Integrated Development Environment for C++ 🛠️",
      video_src: PROJECT_IDE,
      thumbnail: PROJECT_IDE_THUMBNAIL,
      descriptionList: [
        `Java at front-end and Clang compiler linking and executing the files.`,
        `I used Java to write this GUI application which uses JavaFX for user interface.`,
        `Developer can create, delete, load projects and settings of their choice. `,
        `Developer can change the theme, font size, style, and colors`,
        `The file is saved in user preferred location and then is compiled using Clang compiler.`,
      ],
      tags: `C++, Clang, Linker, Compiler, Java, JavaFX, String Parsing`,
      link: "https://github.com/manmeetsingh7781/Projects/tree/master/C%2B%2B%20Compiler%20Beta%20v%201.3",
      link_description: "Check out Github Repository",
    },
    {
      title: "POS System 🏬",
      video_src: PROJECT_POS,
      thumbnail: PROJECT_POS_THUMBNAIL,
      descriptionList: [
        `A full cash register software with user friendly UI`,
        `offer's built-in calculator, track of inventory, age restrictions, and cashier profiles.`,
        `History of previous sales, total sales amounts, be able to add, remove and change inventory`,
      ],
      tags: `Java, JavaFX, JSON, CSS, XML, String Parsing, Multi-threading.`,
      link: "https://github.com/manmeetsingh7781/Projects/tree/master/POS",
      link_description: "Check out Github Repository",
    },
    {
      title: "Earthquake Tracker 📍",
      video_src: PROJECT_EARTHQUAKE,
      thumbnail: PROJECT_EARTHQUAKE_THUMBNAIL,
      descriptionList: [
        `Using an API, parsing the data using JSON and converting it to an Object so that it can be visualized on Map.`,
        `The radius and color of impact is based on impact of earthquake.`,
        `Has a world map and shows the most recent earthquake that happened in the given timeframe.`,
      ],
      tags: `Java, JavaFx, API, JSON, Maps, Visualization, CSS`,
      link: "https://github.com/manmeetsingh7781/Projects/tree/master/UCSDUnfoldingMaps/UCSDUnfoldingMaps",
      link_description: "Check out Github Repository",
    },
    {
      title: "Planner 📅",
      video_src: PROJECT_PLANNER,
      thumbnail: PROJECT_PLANNER_THUMBNAIL,
      descriptionList: [
        `Shows monthly calendar, and user can create tasks and set due dates for tasks`,
        `Tasks can be prioritized indicated by background color, has different stage for each task (To-do, Doing, Done) `,
        `Each task is tracked while in “Doing” stage how long it took to complete.`,
        `Preview of tasks is shown at the calendar grid, making it easy for user to check on tasks he needs todo`,
        `User data is saved in local storage.`,
      ],
      tags: `HTML, CSS, Javascript, ReactJS, Cookies, Local Storage, Material UI, Date and Time`,
      link: "https://master--chimerical-souffle-84346c.netlify.app/",
      link_description: "Check out working demo for this site",
    },
  ];
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Container maxWidth="lg" id="main_container">
        <Box
          margin={{
            xs: "0.5em",
            sm: "2em",
          }}
          textAlign={"left"}
        >
          <HeadingText text="Projects" />
          <Divider />
          <br />
          <Grid container spacing={2}>
            {projects.map((project, index) => (
              <Grid item key={index}>
                <ProjectItem props={project} />
                <br />
                <Divider />
              </Grid>
            ))}

            <Grid item xs={12}>
              <BodyText text={"Website Skills 🌐"} />

              <List>
                <ListItemButton>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText>
                    Experience working with front-end frameworks like Bootstrap,
                    React, React Native, Material UI, and other CSS frameworks.
                  </ListItemText>
                </ListItemButton>

                <ListItemButton>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText>
                    Have experience working with back-end connectiviy, user
                    authentication, connecting databases and making new database
                    schemas, uploading files and folders, user ownerships,
                    sessions, and cookies.
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText>
                    Can also adapt new technologies pretty easily.
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText>
                    I made a Social Media clone back in my Highschool days 2019,
                    using Nodejs, expression, session, user authentication, and
                    database models. I am confindent that I can easily learn
                    anything new as well.
                  </ListItemText>
                </ListItemButton>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </motion.main>
  );
}
