import "./App.css";
import { React, useState } from "react";
import HomeView from "./routes/Home";
import EducationView from "./routes/Education";
import ExperienceView from "./routes/Experience";
import ProjectsView from "./routes/Projects";
import SocialView from "./routes/SocialLinks";
import MyNavbar from "./MyNavBar";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, useScroll } from "framer-motion";
import Footer from "./CustomComps/Footer";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PageNotFound from "./routes/PageNotFound";
import AboutView from "./routes/About";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// put them in .env
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [bannerClosed, setBannerClosed] = useState(false);
  const location = useLocation();
  return (
    <div className="App">
      <MyNavbar currentLocation={location} />
      <div
        style={{
          display: location.pathname === "/" && !bannerClosed ? "flex" : "none",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          gap: 10,
          background: "black",
          borderBottom: "1px solid rgba(0,0,0,0.05)",
          color: "white",
          position: "sticky",
          top: 56,
          zIndex: 100000,
        }}
      >
        <div className="title" style={{ flex: 0.99 }}>
          <h4 style={{ textAlign: "center" }}>
            <a
              href="/projects"
              style={{
                textDecoration: "underline",
                fontWeight: "lighter",
                color: "white",
              }}
            >
              Check out my latest project | Algorithmic Insight Powered by AI -
              Mobile App
            </a>
          </h4>
        </div>
        <div className="closeBtn" style={{ cursor: "pointer" }}>
          <CancelRoundedIcon onClick={() => setBannerClosed(true)} />
        </div>
      </div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route index path="/" element={<HomeView />} />
          {/* <Route path="/about" element={<AboutView />} /> */}
          <Route path="/education" element={<EducationView />} />
          <Route path="/experience" element={<ExperienceView />} />
          <Route path="/projects" element={<ProjectsView />} />
          <Route path="/social" element={<SocialView />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
