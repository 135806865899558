// https://codesandbox.io/p/sandbox/portfolio-npf6t
import { CanvasSpace, Pt, Particle, World, Num, Create } from "pts";

export function myAnimation() {
  let space = new CanvasSpace("#canvas");
  space.background = "black";
  let form = space.getForm();
  var world;

  space.add({
    start: (bound, space) => {
      // Create world and 100 random points
      world = new World(space.innerBound, 1, 0);
      let ballCount = 50;
      let pts = Create.distributeRandom(space.innerBound, ballCount);

      // Create particles and hit them with a random impulse
      for (let i = 0, len = pts.length; i < len; i++) {
        let p = new Particle(pts[i]).size(
          i === 0 ? 30 : 5 + (Math.random() * space.size.x) / 50
        );
        p.hit(Num.randomRange(-50, 50), Num.randomRange(-25, 25));
        world.add(p);
      }

      world.particle(0).lock = true; // lock it to move it by pointer later on
    },

    animate: (time, ftime) => {
      world.drawParticles((p, i) => {
        let color =
          i === 0
            ? "#ffffff"
            : ["#ff2d5d", "#42dc8e", "#2e43eb", "#ffe359", "#41fe70"][i % 5];
        form.fillOnly(color).point(p, p.radius, "circle");
      });

      world.update(ftime);
    },

    action: (type, px, py) => {
      if (type === "move") {
        world.particle(0).position = new Pt(px, py);
      }
    },

    resize: (bound, evt) => {
      if (world) world.bound = space.innerBound;
    },
  });

  return space;
}
