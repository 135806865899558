import React from "react";
import { motion } from "framer-motion";
import "../Styles/Home.css";
import { Container } from "@mui/material";
import { myAnimation } from "../Header";
import { BodyText, HeadingText } from "../CustomComps/Text";

import Content_ProgrammingLangs from "../CustomComps/Content_Technical";
import Content_MotivationView from "../CustomComps/Content_Motivation";
import Content_TopSkills from "../CustomComps/Content_Skills";

export default class HomeView extends React.Component {
  // this handles the animation that is the header on top of the page

  componentDidMount() {
    window.scrollTo(0, 0);
    myAnimation().bindMouse().bindTouch().play();
  }

  render() {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        {/* banner */}
        <div id="container">
          <canvas id="canvas" />
          <div id="myContext">
            <HeadingText text={"Hi, I'm Manmeet!"} />
            <BodyText
              text={
                <span>
                  {"<"}A <span style={{ color: "#42CAFD" }}>Software</span>{" "}
                  Engineer {"/>"}
                </span>
              }
            />
          </div>
        </div>

        {/* Change maxWidth variable for screen sizing */}
        {/* This also handles the image ration in this container */}
        <Container maxWidth="lg" id="main_container">
          <Content_MotivationView />
          <Content_ProgrammingLangs />
          <Content_TopSkills />
        </Container>
      </motion.main>
    );
  }
}
