import { Typography } from "@mui/material";

export function HeadingText({ text }) {
  return (
    <Typography
      sx={{ fontSize: { xs: 32, sm: 64 } }}
      component={"h1"}
      variant="h1"
    >
      {text}
    </Typography>
  );
}

export function BodyText({ text, fontWeight }) {
  return (
    <Typography
      sx={{ fontSize: { xs: 26, sm: 34 }, fontWeight: fontWeight }}
      component={"h1"}
      variant="h4"
    >
      {text}
    </Typography>
  );
}

export function BodyDescription({ text }) {
  return (
    <Typography
      sx={{ fontSize: { xs: 18, md: 22 }, lineHeight: 2 }}
      variant="h5"
      component={"p"}
      color={"text.secondary"}
    >
      {text}
    </Typography>
  );
}
