import React from "react";
import "../Styles/Home.css";
import { BodyDescription, HeadingText } from "./Text";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import svg_motive from "../Images/svgs/svg_motive.svg";
import svg_cmd from "../Images/svgs/undraw_command-line.svg";
import { Typography } from "@mui/material";

export default function Content_MotivationView() {
  return (
    <Box
      id="movite_container"
      margin={{
        xs: "0.5em",
        sm: "2em",
      }}
    >
      <div className="section_title_and_image">
        <img
          alt="Motivation SVG"
          src={svg_motive}
          style={{
            boxSizing: "border-box",
            width: "15%",
          }}
        />
        <HeadingText text="My Self" />
      </div>
      <Divider />

      <Typography
        component={"h3"}
        variant="p"
        sx={{
          // textAlign: "justify",
          fontWeight: "light",
          lineHeight: 2.5,
          // marginTop: "2em",
        }}
      >
        {/* <img alt="Shell Command Pointer" id="small_svg_cmd" src={svg_cmd} /> */}
        Manmeet, a dedicated software engineer with a passion for developing
        high-quality, intricate, and user-friendly applications. My journey in
        the realm of computers began with hands-on experience assembling a
        computer, which sparked my interest and guided me toward a career in
        this exciting field. I'm currently pursuing a Bachelor's degree in
        Computer Science, with a minor in Mathematics, and have cultivated a
        strong foundation in computer programming over the past six years. I
        have strong fundamentals of programming languages such as Java, C, C++,
        Python, Haskell, Prolog, Kotlin, JavaScript, and Assembly language. My
        technical expertise includes web application development, the software
        development lifecycle, object-oriented design/programming, testing,
        debugging, and optimizing algorithm efficiency. I also have hands-on
        experience working with operating system management, including roles as
        a System Administrator, managing users, hosting servers, and ensuring
        security, accessibility, and reliability. I'm skilled in Git, web
        development, React, React Native, databases (SQL/NonSQL),
        troubleshooting, problem-solving, and creating full-stack applications.
        I've worked with IoT devices like Raspberry Pi and Arduino, and have set
        up remote servers. I’ve taken the initiative to host my own server at
        home, accessing my files and website using SSH and Apache webserver.
        I've implemented firewalls, DDNS, and guards to protect against
        vulnerabilities, and am committed to continuous learning and
        improvement. I have a comprehensive understanding of computers and
        applications in general and am comfortable working with Microsoft Office
        applications and virtual machines. I'm familiar with operating systems
        including Windows, Mac, and Linux. I'm always open to connecting with
        others in the tech industry and exploring opportunities to create
        innovative and impactful solutions.
      </Typography>
    </Box>
  );
}
