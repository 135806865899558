import "./App.css";
import "./Styles/Navbar.css";
import { React, useEffect, useState } from "react";
import RESUME_FILE from "./Resume/Resume - Manmeet Singh.pdf";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Box,
  Typography,
  Button,
  Divider,
  CssBaseline,
  AppBar,
  IconButton,
  Drawer,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Link } from "react-router-dom";
import { Download } from "@mui/icons-material";

export default function MyNavbar({ currentLocation }) {
  const [currentTab, setCurrentTab] = useState(currentLocation["pathname"]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = "auto";

  // update only when location changes - keeps track of current active tab
  useEffect(() => {
    setCurrentTab(currentLocation["pathname"]);
  }, [currentLocation]);

  const menuItems = {
    Home: [<HomeIcon />, "/"],
    Experience: [<WorkIcon />, "/experience"],
    Education: [<SchoolIcon />, "/education"],
    Projects: [<AccountTreeIcon />, "/projects"],
    Connect: [<ConnectWithoutContactIcon />, "/social"],
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Side drawer - appears on mobile device
  const sideDrawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        {Object.keys(menuItems).map((item) => (
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href={menuItems[item][1]}
            key={item}
          >
            <ListItem key={item}>
              <ListItemButton sx={{ textAlign: "left" }}>
                <IconButton>{menuItems[item][0]}</IconButton>
                <ListItemText primary={item} />
                <Divider />
              </ListItemButton>
            </ListItem>
          </a>
        ))}
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={RESUME_FILE}
          target="_blank"
          rel="noreferrer"
          download={"Resume - Manmeet Singh"}
          key={"resume-download"}
        >
          <ListItem key="resume">
            <ListItemButton sx={{ textAlign: "left" }}>
              <IconButton>
                <Download />
              </IconButton>
              <ListItemText primary={"Get My Resume"} />
              <Divider />
            </ListItemButton>
          </ListItem>
        </a>
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" component="nav">
          <Toolbar sx={{ background: "rgba(0,0,0,0.8)" }}>
            <Typography
              variant="h6"
              component={"div"}
              sx={{
                flexGrow: 1,
                textAlign: "left",
                display: { xs: "block", sm: "none" },
              }}
            >
              Manmeet Singh
            </Typography>

            {/* Nav bar toggle button Icon displayed on right side of screen only mobiles */}
            <IconButton
              color="inherit"
              aria-label="navbar menu button"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* Nav items for desktop */}
            {/* display: xs -> none when is extra small */}
            {/* display: sm -> block when is small and beyond show the model */}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                width: "100%",
                justifyContent: "center",
              }}
            >
              {Object.keys(menuItems).map((item) => (
                <Link to={menuItems[item][1]} key={item}>
                  <Button
                    sx={{
                      color: "white",
                    }}
                    className={`navbar_item ${
                      currentTab === menuItems[item][1] ? "active_tab" : ""
                    }`}
                  >
                    {item}
                  </Button>
                </Link>
              ))}
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href={RESUME_FILE}
                target="_blank"
                rel="noreferrer"
                download={"Resume - Manmeet Singh"}
                key={"resume-download"}
              >
                <Button
                  key="resume"
                  sx={{
                    color: "white",
                  }}
                >
                  Resume {<Download />}
                </Button>
              </a>
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar />

        {/* for mobile device */}
        <nav>
          <Drawer
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            // For better performance on mobile
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "flex", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {sideDrawer}
          </Drawer>
        </nav>
      </Box>
    </>
  );
}
